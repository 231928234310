const localErrorApplets = {
  not_found_app: {
    id: 'not_found_app',
    _meta: {
      app: 'Errors',
      _appId: 'ERRORS',
      _appletId: 'NOT-FOUND-APP',
      _ownerName: 'YOTE',
      ownerName: 'yote',
      appId: 'errors',
      appletId: 'missing',
      owner: 'yote',
      parent: 'yote_errors',
    },
    name: 'Nothing Here',
    description: 'Sorry, This page may have been deleted by the owner!',
    widgets: [
      { key: 'not_found_app', linkto: 'none' },
    ],
    type: 'Thing',
  },
  not_found_applet: {
    id: 'not_found_applet',
    _meta: {
      app: 'Errors',
      _appId: 'ERRORS',
      _appletId: 'NOT-FOUND-APPLET',
      _ownerName: 'YOTE',
      ownerName: 'yote',
      appId: 'errors',
      appletId: 'missing',
      owner: 'yote',
      username: 'yote',
      parent: 'yote_errors',
    },
    name: 'Nothing Here',
    description: 'Sorry, This page may have been deleted by the owner!',
    widgets: [
      { key: 'not_found_applet', linkto: 'none' },
    ],
    type: 'Thing',
  },
  not_found_user: {
    id: 'not_found_user',
    _meta: {
      app: 'Errors',
      _appId: 'ERRORS',
      _appletId: 'NOT-FOUND-USER',
      _ownerName: 'YOTE',
      ownerName: 'yote',
      owner: 'yote',
      parent: 'yote_errors',
    },
    name: 'USER MISSING',
    description: "Sorry, we cannot find the user you're looking for. The account may have been deleted!",
    type: 'User',
  },
  no_widgets_found: {
    id: 'no_widgets_found',
    _meta: {
      app: 'Errors',
      _appId: 'ERRORS',
      _appletId: 'NO-WIDGETS-FOUND',
      _ownerName: 'YOTE',
      ownerName: 'yote',
      owner: 'yote',
      username: 'yote',
      parent: 'yote_errors',
    },
    name: 'No items found',
    description: 'Sorry, There are no items to display',
    widgets: [
      {
        key: 'no_widgets_found',
        linkto: 'none',
        _meta: {},
      },
    ],
    type: 'Thing',
  },
};
export default localErrorApplets;
