import types from '../../constants';

export const initialState = [];

export const reducer = (state, action) => {
  if (action.type === types.ADD_NOTIFICATION) {
    return [...state, action.notification];
  }
  if (action.type === types.REMOVE_NOTIFICATION) {
    return state.filter((x, index) => index !== action.index);
  }
  return state || initialState;
};

export default reducer;
