import { doc, getDoc } from 'firebase/firestore';
import getFirestore from '..';

function getDocument(path: string) {
  const db = getFirestore();
  const docRef = doc(db, path);
  return getDoc(docRef).then((docSnapshot) => {
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return data;
    }
    return null;
  });
}
export default getDocument;
