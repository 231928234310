import { collection, onSnapshot } from 'firebase/firestore';
import getFirestore from '..';

function watchPath(
  path: string,
  callback: (result: any) => void,
  onErrorCallback?: (error: Error) => void,
) {
  const db = getFirestore();
  const ref = collection(db, path);
  return onSnapshot(
    ref,
    (result) => {
      callback(result);
    },
    (e) => {
      if (onErrorCallback) {
        onErrorCallback(e);
      } else {
        throw e;
      }
    },
  );
}
export default watchPath;
