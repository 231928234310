import {
  getDoc, doc,
} from 'firebase/firestore';
import getFirestore from '../..';

async function usernameAvailable(username: string) {
  const db = getFirestore();
  const userRef = doc(db, 'users', username.toUpperCase());
  const snapshot = await getDoc(userRef);
  return !snapshot.exists();
}
export default usernameAvailable;
