import { RegistrationService } from 'auth';
import {
  Auth, GoogleAuthProvider, sendSignInLinkToEmail, signInWithPopup,
} from 'firebase/auth';
import translations from './translations';

function errorHandler({ code }:any) :never {
  const errorCode = code as keyof typeof translations;
  const errorMessage = translations[errorCode];
  throw new Error(errorMessage);
}
export default function getRegistrationService(auth: Auth): RegistrationService {
  return {
    async registerWithEmail(email) {
      const actionCodeSettings = {
        url: `${process.env.NEXT_PUBLIC_APP_URL}/email-verification`,
        handleCodeInApp: true,
      };
      try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      } catch (error) {
        errorHandler(error);
      }
    },
    async registerWithGoogle() {
      const googleAuthProvider = new GoogleAuthProvider();
      return signInWithPopup(auth, googleAuthProvider)
        .then((result) => result.user)
        .catch(errorHandler);
    },
  };
}
