import types from '../../constants';

export const initialState = {};

export const reducer = (state, action) => {
  if (action.type === types.SET_USER_AUTH) {
    return { ...action.auth };
  }
  return state || initialState;
};

export default reducer;
