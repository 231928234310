import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import storage from '../load-file-storage';

export const defaultMetaData: any = {
  cacheControl: 'public,max-age=31536000',
};

async function uploadFile(
  savePath: string,
  file: File,
  updateProgress?: (percentage: number) => void,
  metadata = defaultMetaData,
) {
  const uploadRef = ref(storage, savePath);

  const uploadTask = uploadBytesResumable(uploadRef, file, metadata);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        if (updateProgress) {
          const percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          updateProgress(percentage);
        }
      },
      (error) => {
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      },
    );
  }) as Promise<string>;
}
export default uploadFile;
