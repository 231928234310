function getTranslations() {
  return {
    'auth/popup-closed-by-user':
      'The popup has been closed by the user before finalizing the operation.',
    'auth/operation-not-allowed': 'This operation is not allowed.',
    'auth/popup-blocked': 'Popup was blocked by browser.',
    'auth/network-request-failed': 'Network error occurred. Check your connection and try again.',
    'auth/invalid-action-code': 'The action code is invalid. This can happen if the link is malformed, expired, or has already been used.',
    serverLoginNotSupported: 'Login is not supported on the server.',
  };
}

const translations = getTranslations();
export default translations;
