import TYPES from '../../constants';

export const initialState = {};

export default function reducer(state, action) {
  if (action
    && action.type === TYPES.SET_LOCAL_PREFERENCE
    && typeof action.key !== 'undefined'
    && typeof action.value !== 'undefined') {
    return { ...state, [action.key]: action.value };
  }
  return state || initialState;
}
