import { LoginService } from 'auth';
import {
  Auth, GoogleAuthProvider, isSignInWithEmailLink, signInWithEmailLink, signInWithPopup, signOut,
} from 'firebase/auth';
import translations from './translations';

export default function getLoginService(auth: Auth): LoginService {
  return {
    isEmailLoginPage: () => {
      if (typeof window === 'undefined') {
        return false;
      }
      return isSignInWithEmailLink(auth, window.location.href);
    },
    signInWithEmail(email, href?:string) {
      if (typeof window === 'undefined') {
        throw new Error(translations.serverLoginNotSupported);
      }
      return signInWithEmailLink(auth, email, href || window.location.href)
        .then((result) => result.user)
        .catch(({ code, message }) => {
          const errorCode = code as keyof typeof translations;
          const errorMessage = translations[errorCode] || message;
          throw new Error(errorMessage);
        });
    },
    async signInWithGoogle() {
      const googleAuthProvider = new GoogleAuthProvider();
      return signInWithPopup(auth, googleAuthProvider)
        .then((result) => result.user)
        .catch(({ code }) => {
          const errorCode = code as keyof typeof translations;
          const errorMessage = translations[errorCode];
          throw new Error(errorMessage);
        });
    },
    signOut() {
      return signOut(auth);
    },
  };
}
