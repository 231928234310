import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Snackbar as SnackBar } from '@mui/material';
import { useNotificationHandlers } from '../notifications-handlers';

const isValidMessage = (message) => {
  if (React.isValidElement(message) || typeof message === 'string') return true;
  return false;
};

function Notifier() {
  const { removeNotification } = useNotificationHandlers();
  const notifications = useSelector((state) => state.notifications);

  if (notifications.length === 0) return null;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    removeNotification(0);
  };

  const type = notifications[0].type === 'failure' ? 'error' : notifications[0].type;

  return (
    <SnackBar
      open={!!notifications[0]}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      key={`notification${Math.random()}`}
      autoHideDuration={notifications[0].timeout || 3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {isValidMessage(notifications[0].message) ? notifications[0].message : 'Loading...'}
      </Alert>
    </SnackBar>
  );
}

export default Notifier;
