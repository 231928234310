import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import getFirestore from '../..';

const appletIdAvailableInApp = async (appletId: string, appKey: string, uid: string) => {
  const db = getFirestore();
  const appletRef = collection(db, 'applets');
  const q = query(
    appletRef,
    where('_meta.owner', '==', uid),
    where('_meta.app', '==', appKey),
    where('_meta._appletId', '==', `${appletId}`.toUpperCase()),
  );
  const snapshot = await getDocs(q);
  if (snapshot.empty) return Promise.resolve(true);
  return Promise.resolve(false);
};

export default appletIdAvailableInApp;
