/* eslint-disable no-underscore-dangle */
import types from '../constants';
import errorApplets from '../local-error-applets';

export const initialState = { ...errorApplets };
const loadInitialState = () => {
  let appletsCache = {};
  if (typeof window !== 'undefined' && window.__NEXT_DATA__) {
    try {
      appletsCache = { ...window.__NEXT_DATA__.props.pageProps.cache.applets };
    } catch (e) {
      appletsCache = {};
    }
  }
  return { ...initialState, ...appletsCache };
};
export const reducer = (_state, action) => {
  const state = { ...loadInitialState(), ..._state };
  if (action.type === types.SET_APPLET && action.id && action.applet) {
    const newState = { ...state };

    const isNew = newState[action.id]
      && action.applet._meta.updated > (newState[action.id]._meta.updated || 0);

    if (isNew || !newState[action.id]) {
      newState[action.id] = action.applet;
    }
    return newState;
  }

  if (action.type === types.REMOVE_APPLET && action.id) {
    const { [action.id]: itemToDelete, ...remaining } = { ...state };
    return remaining;
  }

  if (action.type === types.SET_APPLETS && action.applets) {
    const newApplets = Object.entries(action.applets)
      .filter(([key, value]) => !(state[key] && state[key]._meta.updated >= value._meta.updated))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), { ...state });
    return { ...newApplets };
  }
  if (action.type === types.UPDATE_APPLETS_OWNERNAME && action.ownerName && action.oldOwnerName) {
    const updated = Object.entries(state)
      .filter(entry => entry[1]._meta.ownerName === action.oldOwnerName)
      .reduce((acc, [key, applet]) => ({
        ...acc,
        [key]: {
          ...applet,
          _meta: {
            ...applet._meta,
            ownerName: action.ownerName,
            _ownerName: `${action.ownerName}`.toUpperCase(),
          },
        },
      }), {});
    return { ...state, ...updated };
  }
  return state;
};

export default reducer;
