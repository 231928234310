/* eslint-disable no-underscore-dangle */
import types from '../../constants';
import ERRORS_APPLETS from '../../local-error-applets';

const errorWidgets = {
  [ERRORS_APPLETS.not_found_app.id]: ERRORS_APPLETS.not_found_app.widgets,
  [ERRORS_APPLETS.not_found_applet.id]: ERRORS_APPLETS.not_found_applet.widgets,
  [ERRORS_APPLETS.not_found_user.id]: ERRORS_APPLETS.not_found_user.widgets,
};

export const initialState = { ...errorWidgets };
function loadInitialServerState() {
  let widgetsCache = { ...initialState };
  if (typeof window !== 'undefined' && window.__NEXT_DATA__) {
    try {
      widgetsCache = { ...widgetsCache, ...window.__NEXT_DATA__.props.pageProps.cache.widgets };
    } catch (e) {
      //
    }
  }
  return widgetsCache;
}

export const reducer = (_state, action) => {
  const state = { ...loadInitialServerState(), ..._state };
  if (action.type === types.SET_WIDGETS && action.id && action.widgets) {
    const newState = { ...state };
    newState[action.id] = action.widgets;
    return newState;
  }

  if (action.type === types.REMOVE_WIDGET && action.id && action.key) {
    const newState = { ...state };
    const oldWidgets = newState[action.id];
    const newWidgets = oldWidgets.filter(w => w.key !== action.key);
    newState[action.id] = newWidgets;
    return newState;
  }

  return state;
};

export default reducer;
