/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext } from 'react';

type YoteAnalytics = {
  setCurrentScreen: (screenName: string) => void;
  logEvent: (eventName: string, params?: any) => void;
  setUserId: (userId: string) => void;
  setUserProperties: (properties: any) => void;
}

export const defaultAnalytics:YoteAnalytics = {
  logEvent: () => { },
  setUserId: () => { },
  setCurrentScreen: () => { },
  setUserProperties: () => { },
};

const context = React.createContext(defaultAnalytics);
export const { Provider, Consumer } = context;
export const useAnalyticsContext = () => {
  const value = useContext(context);
  return value;
};
export default context;
