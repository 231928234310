import { enableMultiTabIndexedDbPersistence, getFirestore } from 'firebase/firestore';
import initializeFirebase from '../init';

const loadDb = () => {
  initializeFirebase();
  const firestore = getFirestore();
  try {
    if (typeof window !== 'undefined') {
      enableMultiTabIndexedDbPersistence(firestore)
        .catch((err) => {
        // ignore
        });
    }
  } catch (error) {
    // ignore
  }

  return firestore;
};

export default loadDb;
