import useNavigation from 'packages/use-navigation';
import { useEffect } from 'react';
import { useAnalyticsContext } from 'analytics-context';

function useRouteTracker() {
  const {
    location: { pathname, search },
  } = useNavigation();

  const { setCurrentScreen } = useAnalyticsContext();

  useEffect(() => {
    setCurrentScreen(pathname! + search!);
  }, [pathname, search, setCurrentScreen]);

  return null;
}

export default useRouteTracker;
