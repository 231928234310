import React, { useEffect } from 'react';
import initializeFirebase from 'packages/firebase/init';
import performanceMonitoring from 'performance-monitoring';
import AnalyticsTracker from 'with-google-analytics/analytics-route-tracker';
import AuthAnalyticsTracker from 'packages/auth/auth-analytics-tracker';
import Router from 'init/router';
import { useNotificationHandlers } from 'App/notifications-handlers';
import { Provider as NotificationProvider } from 'notifications-context';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { DbProvider } from 'App/context/db';
import Notifier from 'App/notifier';
import useTheme from 'App/use-theme';
import { firebaseDb } from 'packages/db';
import { store } from 'packages/db/store';
import { Provider } from 'react-redux';
import { authService } from 'packages/firebase/auth';
import { Provider as AuthProvider } from 'auth/context';
import { AppProps } from 'next/app';
import NoSSR from '../src/no-ssr';
import './index.css';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function WithDbContext({ children }:{children:React.ReactNode}) {
  return (
    <Provider store={store}>
      <DbProvider value={firebaseDb}>
        {children}
      </DbProvider>
    </Provider>
  );
}

function WithNotificationProvider({ children }:{children:React.ReactNode}) {
  const { addNotification } = useNotificationHandlers();
  return (
    <NotificationProvider value={addNotification}>
      {children}
      <NoSSR>
        <Notifier />
      </NoSSR>
    </NotificationProvider>
  );
}
function WithMuiTheme({ children }:{children:React.ReactNode}) {
  const theme = useTheme();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function App({ Component, pageProps }:AppProps) {
  useEffect(() => {
    initializeFirebase();
    performanceMonitoring();
    AuthAnalyticsTracker();
  }, []);
  return (
    <Router>
      <AnalyticsTracker />
      <WithDbContext>
        <WithMuiTheme>
          <WithNotificationProvider>
            <AuthProvider value={authService}>
              <Component {...pageProps} />
            </AuthProvider>
          </WithNotificationProvider>
        </WithMuiTheme>
      </WithDbContext>
    </Router>
  );
}

export default App;
