import React from 'react';
import { useNavigationContext } from './navigation-context';

export default function useNavigation() {
  return useNavigationContext();
}
export function withNavigation(Component: any) {
  function WithNavigation(props: any) {
    const navigation = useNavigation();
    return <Component {...props} {...navigation} />;
  }

  WithNavigation.displayName = `withNavigation(${Component.displayName || Component.name})`;
  return WithNavigation;
}
