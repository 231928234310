import React, { useEffect } from 'react';

export default function NoSSR({ children }: { children: React.ReactNode }) {
  const [canRender, setCanRender] = React.useState(false);
  useEffect(() => {
    setCanRender(true);
  }, []);
  if (!canRender) return null;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
