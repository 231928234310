import { AuthService } from 'auth';
import { createContext, useContext } from 'react';

export const defaultAuthContext:AuthService = {
  registerWithEmail: () => {
    throw new Error('Auth Service not Initialized or method not implemented');
  },
  registerWithGoogle: () => {
    throw new Error('Auth Service not Initialized or method not implemented');
  },
  isEmailLoginPage: () => {
    throw new Error('Auth Service not Initialized or method not implemented');
  },
  signInWithEmail: () => {
    throw new Error('Auth Service not Initialized or method not implemented');
  },
  signInWithGoogle: () => {
    throw new Error('Auth Service not Initialized or method not implemented');
  },
  signOut: () => {
    throw new Error('Auth Service not Initialized or method not implemented');
  },
  getCurrentUser: () => {
    throw new Error('Auth Service not Initialized or method not implemented');
  },
  isLoggedIn: () => {
    throw new Error('Auth Service not Initialized or method not implemented');
  },
  isLoggedOut: () => {
    throw new Error('Auth Service not Initialized or method not implemented');
  },
  onAuthStateChanged: () => {
    throw new Error('Auth Service not Initialized or method not implemented');
  },
};

const AuthContext = createContext<AuthService>(defaultAuthContext);

export const { Provider, Consumer } = AuthContext;

export const useAuthContext = () => useContext(AuthContext);

export default AuthContext;
