import { doc, onSnapshot } from 'firebase/firestore';
import getFirestore from '..';

function watchApplet(
  appletKey: string,
  callback: (result: any) => void,
  onErrorCallback?: (error: Error) => void,
) {
  const db = getFirestore();
  const ref = doc(db, 'applets', appletKey);
  return onSnapshot(
    ref,
    (snapshot) => {
      if (snapshot.exists()) {
        callback({ ...snapshot.data(), id: appletKey });
      }
    },
    (e) => {
      if (onErrorCallback) {
        onErrorCallback(e);
      } else throw e;
    },
  );
}
export default watchApplet;
