import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import getFirestore from '../..';

const appIdAvailableAmongUserApps = async (appId: string, uid: string) => {
  const db = getFirestore();
  const appletRef = collection(db, 'applets');

  const q = query(
    appletRef,
    where('_meta.owner', '==', uid),
    where('_meta._appId', '==', `${appId}`.toUpperCase()),
    where('_meta._appletId', '==', `${appId}`.toUpperCase()),
  );
  const snapshot = await getDocs(q);
  if (snapshot.empty) return Promise.resolve(true);
  return Promise.resolve(false);
};

export default appIdAvailableAmongUserApps;
