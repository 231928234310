import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const defaultTheme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
  },
});
const darkTheme = createTheme({
  ...defaultTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: '#00f4d7',
    },
    secondary: {
      main: '#ef5350',
    },
  },
});
export default function useTheme() {
  let systemUsesDarkTheme = false;
  if (typeof window !== 'undefined' && window.matchMedia) {
    systemUsesDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
  const darkMode = useSelector(({ localPreferences }) => {
    if (typeof localPreferences.darkMode === 'undefined') {
      return systemUsesDarkTheme;
    }
    return localPreferences.darkMode;
  });
  return darkMode ? darkTheme : defaultTheme;
}
