function getTranslations() {
  return {
    'auth/popup-closed-by-user':
      'The popup has been closed by the user before finalizing the operation.',
    'auth/operation-not-allowed': 'This operation is not allowed.',
    'auth/popup-blocked': 'Popup was blocked by browser.',
    'auth/network-request-failed': 'Network error occurred. Check your connection and try again.',
    'auth/email-already-in-use': 'Email has already been used',
    'auth/invalid-email': 'Email is invalid',
    'auth/weak-password': 'Password is not secure',
  };
}

const translations = getTranslations();
export default translations;
