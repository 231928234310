import React from 'react';
import {
  LinkProps as LinkMUIProps, Typography, useTheme,
} from '@mui/material';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

type LinkProps = Omit<LinkMUIProps, 'color' | 'href' | 'classes'> &
  Pick<NextLinkProps, 'href' | 'as' | 'prefetch'> & {to: string | {pathname: string}};
const YoteLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({
    to, as, prefetch, ...props
  }, ref) => {
    const hrefPath = typeof to === 'string' ? to : to.pathname;
    const theme = useTheme();
    return (
      <Typography color={theme.palette.primary.main} component="span">
        <NextLink
          as={as}
          prefetch={prefetch}
          ref={ref}
          scroll={false}
          {...props}
          href={hrefPath}
        />
      </Typography>
    );
  },
);

export default YoteLink;
