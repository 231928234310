/* eslint-disable no-unused-vars */
import IModel, {
  IApplet,
  ICondition,
  IDBOperation,
  IQueryResult,
} from 'packages/model/interface';
import React, { useContext } from 'react';

const defaultDbContext: IModel = {
  FileStorage: {
    upload: () => {
      throw new Error('FileStorage is not implemented');
    },
  },
  get(path: string): Promise<any> {
    throw new Error('Function not implemented.');
  },
  getApplet(appletKey: string): Promise<IApplet> {
    throw new Error('Function not implemented.');
  },
  batch(ops: IDBOperation[]): Promise<any> {
    throw new Error('Function not implemented.');
  },
  check: {
    usernameIsAvailable(username: string): Promise<boolean> {
      throw new Error('Function not implemented.');
    },
    appIdIsAvailable(appId: string, uid: string): Promise<boolean> {
      throw new Error('Function not implemented.');
    },
    appletIdIsAvailable(
      appletId: string,
      appKey: string,
      uid: string,
    ): Promise<boolean> {
      throw new Error('Function not implemented.');
    },
  },
  onDeleteProperty: () => {
    throw new Error('Function not implemented.');
  },
  update(path: string, data: any): Promise<any> {
    throw new Error('Function not implemented.');
  },
  generateKey(keyPath?: string | undefined): string {
    throw new Error('Function not implemented.');
  },
  getHostSettings(host: string): Promise<any> {
    throw new Error('Function not implemented.');
  },
  query(collection: string, conditions: ICondition[]): Promise<IQueryResult[]> {
    throw new Error('Function not implemented.');
  },
  watchApplet(
    appletID: string,
    onUpdateCallback: (applet: IApplet) => any,
    onErrorCallback?: ((error: Error) => void) | undefined,
  ): () => void {
    throw new Error('Function not implemented.');
  },
  watchPath(
    path: string,
    onUpdateCallback: (results: any) => any,
    onErrorCallback?: ((error: Error) => void) | undefined,
  ): () => void {
    throw new Error('Function not implemented.');
  },
};

const context = React.createContext(defaultDbContext);
export const { Provider: DbProvider } = context;

export const useDbContext = () => useContext(context);
