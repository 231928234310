import React from 'react';
import { useRouter } from 'next/router';
import navigationContext from 'packages/use-navigation/navigation-context';
import YoteLink from './link';

function useNextRouterNav() {
  const router = useRouter();
  const history = {
    push: router.push,
    replace: router.replace,
  };
  const location = {
    pathname: router.asPath,
    search: router.query,
  };
  const appNav = {
    Link: YoteLink,
    history,
    location: location as unknown as Location,
  };
  return appNav;
}
const { Provider: NavProvider } = navigationContext;

function Inner({ children }: { children: React.ReactNode }) {
  const nav = useNextRouterNav();
  return <NavProvider value={nav}>{children}</NavProvider>;
}

export default function Router({ children }: { children: React.ReactNode }) {
  return <Inner>{children}</Inner>;
}
