import { loadAuth } from 'packages/firebase/auth';

export function authStateChangeCallback(user) {
  return import('packages/firebase/analytics').then(({ default: analytics }) => {
    if (user && user.uid) {
      analytics.setUserId(user.uid);
    } else {
      analytics.setUserId('none');
    }
  });
}

export default () => {
  const auth = loadAuth();

  return auth.onAuthStateChanged(authStateChangeCallback);
};
