import { initializeApp } from 'firebase/app';
import credentials from '../credentials';

function initializeFirebase() {
  try {
    initializeApp(credentials());
  } catch (error) {
    // ignore app already initialized error
  }
}

export default initializeFirebase;
