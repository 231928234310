import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthService } from 'auth';
import { defaultAuthContext } from 'auth/context';
import initializeFirebase from '../init';
import getRegistrationService from './registation';
import getLoginService from './login';

export const loadAuth = () => {
  initializeFirebase();
  return getAuth();
};

export const auth = loadAuth();

export const authService: AuthService = {
  ...defaultAuthContext,
  ...getRegistrationService(auth),
  ...getLoginService(auth),
  onAuthStateChanged: (callback) => onAuthStateChanged(auth, (user) => {
    callback(user);
  }),
  getCurrentUser: () => auth.currentUser,
};

export default auth;
