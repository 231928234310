import { useDispatch } from 'react-redux';
import TYPES from 'packages/db/redux/constants';

export function useNotificationHandlers() {
  const dispatch = useDispatch();

  function addNotification(notification) {
    dispatch({ type: TYPES.ADD_NOTIFICATION, notification });
  }
  function removeNotification(index) {
    dispatch({ type: TYPES.REMOVE_NOTIFICATION, index });
  }

  return {
    addNotification,
    removeNotification,
  };
}

export default useNotificationHandlers;
