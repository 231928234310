import get from '../get';

const getHostSettings = async (host:string) => {
  try {
    const hostSettings = await get(`hosts/${host}`);
    return hostSettings;
  } catch {
    return null;
  }
};
export default getHostSettings;
