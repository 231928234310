/* eslint-disable no-underscore-dangle */
import types from '../../constants';

export const initialState = {};

const loadInitialState = () => {
  let referencesCache = {};
  if (typeof window !== 'undefined' && window.__NEXT_DATA__) {
    try {
      referencesCache = { ...window.__NEXT_DATA__.props.pageProps.cache.references };
    } catch (e) {
      referencesCache = {};
    }
  }
  return referencesCache;
};

export function reducer(_state, action) {
  const state = { ...loadInitialState(), ..._state };
  if (action.type === types.SET_REFERENCE && action.id && action.applet) {
    const newState = { ...state };
    let reference;
    if (action.applet._meta._username) {
      reference = `/@${action.applet._meta._username}`;
    } else if (action.applet._meta._appId === action.applet._meta._appletId) {
      reference = `/@${action.applet._meta._ownerName}/${action.applet._meta._appId}`;
    } else {
      reference = `/@${action.applet._meta._ownerName}/${action.applet._meta._appId}/${action.applet._meta._appletId}`;
    }
    newState[`${reference}`] = action.id;
    return newState;
  }

  if (action.type === types.REMOVE_REFERENCE && action.reference) {
    const { [action.reference]: toBeRemoved, ...remaining } = state;
    return remaining;
  }
  return state;
}

export default reducer;
