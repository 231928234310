import { doc, writeBatch } from 'firebase/firestore';
import { IDBOperation } from '../../../model/interface';
import getFirestore from '..';
import { loadAuth } from '../../auth';

const batchUpdate = async (ops: IDBOperation[]) => {
  const auth = loadAuth();
  const { uid } = (auth && auth.currentUser) || { uid: null };
  const db = getFirestore();
  const batch = writeBatch(db);

  ops.forEach((op) => {
    const {
      collection, id, data, options,
    } = op;
    const ref = doc(db, `${collection || 'applets'}/${id}`);
    if (data && op.action !== 'delete') {
      data._meta = {
        owner: uid,
        ...data._meta,
        updated: Math.floor(Date.now() / 1000),
      };
      batch.set(ref, data, { merge: true, ...options });
    }
    if (op.action === 'delete') {
      batch.delete(ref);
    }
  });
  return batch.commit();
};

export default batchUpdate;
