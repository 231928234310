const REDUX_ACTIONS = {
  SET_APPLET: 'SET_APPLET',
  SET_LOCAL_PREFERENCE: 'SET_LOCAL_PREFERENCE',
  SET_APPLETS: 'SET_APPLETS',
  SET_HOST_SETTINGS: 'SET_HOST_SETTINGS',
  UPDATE_APPLETS_OWNERNAME: 'UPDATE_APPLETS_OWNERNAME',
  REMOVE_APPLET: 'REMOVE_APPLET',
  SET_REFERENCE: 'SET_REFERENCE',
  REMOVE_REFERENCE: 'REMOVE_REFERENCE',
  SET_WIDGETS: 'SET_WIDGETS',
  REMOVE_WIDGET: 'REMOVE_WIDGET',
  SET_USER_AUTH: 'SET_USER_AUTH',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
};
export default REDUX_ACTIONS;
