import { deleteField } from 'firebase/firestore';
import IModel from '../model/interface';
import fileUpload from './file-storage/file-upload';
import batchUpdate from './firestore/batch-update';
import checkAppIdAvailable from './firestore/check/appId-available';
import checkAppletIdAvvailable from './firestore/check/appletId-available';
import checkUsernameAvailable from './firestore/check/username-available';
import generateId from './firestore/generate-id';
import getDomainSettings from './firestore/get-host-settings';
import firestoreGet from './firestore/get';
import getAppletByKey from './firestore/get-applet-by-key';
import firebaseQuery from './firestore/query';
import updateDocument from './firestore/update';
import watchTheApplet from './firestore/watch-applet';
import watchAPath from './firestore/watch-path';

const db: IModel = {
  FileStorage: { upload: fileUpload },
  batch: batchUpdate,
  check: {
    appIdIsAvailable: checkAppIdAvailable,
    appletIdIsAvailable: checkAppletIdAvvailable,
    usernameIsAvailable: checkUsernameAvailable,
  },
  generateKey: generateId,
  get: firestoreGet,
  getApplet: getAppletByKey,
  getHostSettings: getDomainSettings,
  onDeleteProperty: deleteField,
  query: firebaseQuery,
  update: updateDocument,
  watchApplet: watchTheApplet,
  watchPath: watchAPath,
};
export default db;
