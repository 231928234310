import React from 'react';

type LinkProps = {
  to: string;
  children: React.ReactNode;
}

export default function DefaultLink({ to, children }:LinkProps) {
  return <a href={to}>{children}</a>;
}
