import {
  collection,
  getDocs,
  query,
  QueryConstraint,
  where,
  WhereFilterOp,
} from 'firebase/firestore';
import { ICondition, IQueryResult } from '../../../model/interface';
import getFirestore from '..';

export default async (_collection: string, conditions: ICondition[]): Promise<IQueryResult[]> => {
  const db = getFirestore();
  const ref = collection(db, _collection);
  const queryConstraints: QueryConstraint[] = [];
  conditions.forEach((condition) => {
    if (
      Array.isArray(condition)
      && condition.length === 3
      && condition[0]
      && typeof condition[0] === 'string'
      && condition[1]
      && typeof condition[1] === 'string'
      && typeof condition[2] !== 'undefined'
    ) {
      queryConstraints.push(where(condition[0], condition[1] as WhereFilterOp, condition[2]));
    }
  });
  const q = query(ref, ...queryConstraints);

  return getDocs(q).then((querySnapshot) => {
    const result: IQueryResult[] = [];
    querySnapshot.docs.forEach((docSnap) => {
      result.push({ id: docSnap.id, data: docSnap.data() });
    });

    return result;
  });
};
