import React, { useContext } from 'react';
import defaultLink from './link';

function getLocation() {
  if (typeof window === 'undefined') {
    return {
      assign: () => { /* DO Nothing */ },
      replace: () => { /* DO Nothing */ },
      pathname: undefined,
      search: undefined,
    };
  }
  return window.location;
}
type RouteOptions = {
  pathname: string;
  query?: Record<string, string>;
}

const defaultNavigation = {
  Link: defaultLink as any,
  history: {
    push: (path: string | RouteOptions) => {
      if (typeof path === 'string') {
        getLocation().assign(path);
      } else {
        const { pathname, query } = path;
        const search = new URLSearchParams(query).toString();
        getLocation().assign(`${pathname}?${search}`);
      }
    },
    replace: (path: string| RouteOptions) => {
      if (typeof path === 'string') {
        getLocation().replace(path);
      } else {
        const { pathname, query } = path;
        const search = new URLSearchParams(query).toString();
        getLocation().replace(`${pathname}?${search}`);
      }
    },
  },
  location: {
    pathname: getLocation().pathname,
    search: getLocation().search,
  },
};
const navigationContext = React.createContext(defaultNavigation);

export const useNavigationContext = () => {
  const value = useContext(navigationContext);
  return value;
};

export const Link = React.forwardRef((props:any, ref:any) => {
  const { Link: ContextLink } = useNavigationContext();
  return <ContextLink {...props} ref={ref} />;
});

export default navigationContext;
