import TYPES from '../../constants';

export const initialState = {
  'localhost:3000': {
    mode: 'yote',
  },
  'yote.app': {
    mode: 'yote',
  },
};

export default function reducer(state, action) {
  if (action
    && action.type === TYPES.SET_HOST_SETTINGS
    && typeof action.host === 'string' && action.host
    && action.settings) {
    return { ...state, [action.host]: action.settings };
  }
  return state || initialState;
}
