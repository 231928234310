import { configureStore } from '@reduxjs/toolkit';
import applets from '../redux/reducers';
import auth from '../redux/reducers/auth-user';
import notifications from '../redux/reducers/notifications';
import references from '../redux/reducers/applet-reference';
import hostSettings from '../redux/reducers/host-settings';
import widgets from '../redux/reducers/widgets';
import localPreferences from '../redux/reducers/local-preferences';
import { loadState, saveState } from './local-storage';

const reducers = ({
  references, applets, auth, notifications, widgets, localPreferences, hostSettings,
});

export const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: loadState(),
});
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
