import { IApplet } from 'packages/model/interface';
import { doc, getDoc } from 'firebase/firestore';
import getFirestore from '..';

async function getAppletByKey(key: string) {
  const db = getFirestore();
  const docRef = doc(db, 'applets', key);
  return getDoc(docRef).then((docSnapshot) => docSnapshot.data() as IApplet);
}

export default getAppletByKey;
